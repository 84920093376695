import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FiSearch } from "react-icons/fi";
import config from '../config.json';
import axios from 'axios';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';

const Search = ({ className, onClose }) => {
    const [query, setQuery] = useState('');
    const [liveSearch, setLiveSearch] = useState([]);
    const searchRef = useRef(null);
    const linkRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            linkRef.current.click();
        }
    };
    const fetchDetails = async (searchQuery) => {
        if (searchQuery.trim() === '') {
            setLiveSearch([]);
            return;
        }

        try {
            const response = await axios.get(`${config.TMDBapiUrl}search/multi`, {
                headers: {
                    Authorization: `Bearer ${config.TMDBToken}`
                },
                params: {
                    query: searchQuery,
                    include_adult: false,
                    language: 'en-US',
                    page: 1,
                    api_key: config.TMDBapikey
                }
            });
            setLiveSearch(response.data.results);
        } catch (error) {
            console.error('Error fetching item details', error);
        }
    };

    const debouncedFetchDetails = useCallback(debounce((query) => {
        fetchDetails(query);
    }, 300), []);

    useEffect(() => {
        debouncedFetchDetails(query);
    }, [query, debouncedFetchDetails]);

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleLinkClick = () => {
        setQuery('');
        onClose();
    };

    return (
        <div ref={searchRef} className={` fixed z-30 w-full left-0 duration-300 ${className}`}>
            <div className='md:px-12 px-0 py-3'>
                <div className='bg-gray-900/80 backdrop-blur-md p-5 rounded-lg'>
                    <p className='text-md font-semibold pl-2'>Search</p>
                    <div className='w-full flex items-center gap-2 my-2'>
                        <input
                            className='w-full bg-gray-800/70 border border-gray-500 py-2 px-4 rounded-full'
                            type="search"
                            placeholder='Search Movie & TV'
                            value={query}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <Link 
                        ref={linkRef} 
                        onClick={handleLinkClick} 
                        to={`/search/${query}`} 
                        className='flex items-center gap-1 bg-yellow-500 rounded-full md:py-2 py-3 px-4 text-black font-semibold'>
                        <FiSearch /><span className=' hidden md:block'>Search</span>
                        </Link>
                    </div>
                    <div id='LiveResults'>
                        <ul>
                            {liveSearch.map((result) => (
                                <li className='w-full' key={result.id}>
                                    <Link
                                        className='hover:text-yellow-500 hover:underline w-full block'
                                        to={`${result.media_type == 'movie'? `/${result.id}/${result.title || result.name}` : `/tv/${result.id}/${result.title || result.name}`} `}
                                        onClick={handleLinkClick}
                                    >
                                        {result.title || result.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Search;

import React, { useEffect, useState } from 'react';
import { Link, useParams,} from 'react-router-dom';
import Nav from './components/Nav'
import config from './config.json';
import axios from 'axios';
import { PiVideoBold } from "react-icons/pi";
import { FaPlay } from "react-icons/fa";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";



const TvDetails = () => {
    const { id } = useParams();
    const [Details, setDetails] = useState(null);
    const [MovieLogo, setMovieLogo] = useState([]);
    const [trailer, setTrailer] = useState(null);
    const [isTrailerPlaying, setIsTrailerPlaying] = useState(false);
    const [IMDBId, setIMDBId] = useState(null);

    const handlePlayTrailer = () => {
      setIsTrailerPlaying(true);
    };
  
    const handleCloseTrailer = (event) => {
      if (event.target.id === 'TrailerVideo') {
        setIsTrailerPlaying(false);
      }
    };

 
    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axios.get(`${config.TMDBapiUrl}tv/${id}`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
                setDetails(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching item details', error);
            }
        };

        fetchDetails();
    }, [id]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${config.TMDBapiUrl}tv/${id}/images`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
                const images = response.data.logos;                
                // Filter images with iso_639_1: 'en'
                const filteredImages = images.filter(image => image.iso_639_1 === 'en');
                // Get a single result from the filtered list
                const singleImage = filteredImages.length > 0 ? filteredImages[0] : null;
                setMovieLogo(singleImage);
            } catch (error) {
                console.error('Error fetching images', error);
            }
        };
    
        fetchImages();
    }, [id]);
    
    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(`${config.TMDBapiUrl}tv/${id}/videos`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
                console.log(response.data.results);
    
                // Step 1: Try to find an official trailer
                const officialTrailers = response.data.results.filter(video => video.official && video.name.toLowerCase().includes('official trailer'));
                
                // Step 2: If no official trailers, look for any trailer
                const trailers = officialTrailers.length > 0 ? officialTrailers : response.data.results.filter(video => video.name.toLowerCase().includes('trailer'));
                
                // Step 3: If no trailers at all, pick a random video
                const selectedVideo = trailers.length > 0 ? trailers[0] : (response.data.results.length > 0 ? response.data.results[0] : null);
                
                setTrailer(selectedVideo);
                console.log('video trailer', selectedVideo);
            } catch (error) {
                console.error('Error fetching videos', error);
            } 
        };
        fetchVideos();
    }, [id]);

    useEffect(() => {
        const fetchIds = async () => {
            try {
                const response = await axios.get(`${config.TMDBapiUrl}tv/${id}/external_ids`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
                console.log(response.data.imdb_id);
                setIMDBId(response.data.imdb_id)
            } catch (error) {
                console.error('Error fetching videos', error);
            } 
        };
        fetchIds();
    }, [id]);
   

      
    

    
    

    



      
      const [isInList, setIsInList] = useState(false);

        useEffect(() => {
            if(Details){
                const existingMoviesList = JSON.parse(localStorage.getItem('TvList')) || [];
                const movieExists = existingMoviesList.some(movie => movie.id === Details.id);
                setIsInList(movieExists);
            }
        }, [Details]);

        const handleMoviesList = () => {
            const existingMoviesList = JSON.parse(localStorage.getItem('TvList')) || [];
        
            if (isInList) {
              const updatedMoviesList = existingMoviesList.filter(movie => movie.id !== Details.id);
              localStorage.setItem('TvList', JSON.stringify(updatedMoviesList));
              setIsInList(false);
            } else {
              const movieDetails = {
                title: Details.name,
                id: Details.id,
                backdrop: `https://image.tmdb.org/t/p/original/${Details.backdrop_path}`,
                poster: `https://image.tmdb.org/t/p/original/${Details.poster_path}`,
              };
              const updatedMoviesList = [...existingMoviesList, movieDetails];
              localStorage.setItem('TvList', JSON.stringify(updatedMoviesList));
              setIsInList(true); 
            }
          };



    return (
        <div>
            
            <Nav />
        {Details &&
            <div className='w-full h-screen bg-cover bg-opacity-50' style={{backgroundImage: `url(https://image.tmdb.org/t/p/original/${Details?.backdrop_path})`}}>
                <div className='w-full h-screen bg-gray-950/50 backdrop-blur-sm'>
                <div className=' grid grid-cols-10'>
                <div className=' col-span-7 pl-12 pt-16 flex justify-center flex-col'>
                  {MovieLogo ? 
                  (<img className=' h-[7rem] w-[30rem] object-contain object-left' src={`https://image.tmdb.org/t/p/original/${MovieLogo.file_path}`} alt="" />):
                  (
                    <p className='text-4xl font-bold'>{Details.name}</p>
                  )
                  }
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-5 mb-1'>Status</div>
                    <div className='flex items-center gap-3'>
                    <div className=' bg-gray-800/30 text-sm border border-gray-700 text-gray-200 py-1.5 px-3 rounded-full'>{Details.status}</div>
                    <div className=' bg-gray-800/30 text-sm border border-gray-700 text-gray-200 py-1.5 px-3 rounded-full'>{Details.first_air_date}</div>
                    </div>
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-3 mb-1'>Genres</div>
                    <div className='flex items-center gap-3'>
                    {Details.genres.map((genre, index) => (
                        <p className=' bg-gray-800/30 text-sm border border-gray-700 text-gray-200 py-1.5 px-3 rounded-full' key={index}>{genre.name}</p>
                        ))}
                    </div>
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-3 mb-1'>Tagline</div>
                    <div className=' bg-gray-800/30 text-sm border border-gray-700 text-gray-200 py-1.5 px-3 rounded-full w-max'>{Details.tagline}</div>
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-3 mb-1'>Production Companies</div>
                    <div className='flex items-center gap-3 pl-2'>
                    {Details.production_companies.map((company, index) => (
                        company.logo_path && (
                            <img key={index} className='w-16' src={`https://image.tmdb.org/t/p/original/${company.logo_path}`} alt="" />
                        )
                    ))}
                    </div>
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-3 mb-1'>Overview / Plot</div>
                    <p className='text-sm w-[70%] pl-2 text-gray-200'>{Details.overview}</p>

                    <div className='flex items-center gap-2 mt-4'>
                    {trailer && 
                     <div>
                     <button
                       className='text-xl bg-gray-900/30 border border-gray-400/50 hover:bg-yellow-500 hover:border-yellow-200 hover:text-black rounded-full flex items-center justify-center py-2.5 px-4 gap-2'
                       onClick={handlePlayTrailer}
                     >
                       <PiVideoBold />
                       <span className='text-sm'>Play Trailer</span>
                     </button>
               
                   </div>
                    
                    }
                    <button 
                    onClick={handleMoviesList}
                    className={`${isInList ? ' bg-yellow-500 text-black font-semibold' : 'bg-gray-900/30'} text-xl bg-gray-900/30 border border-gray-400/50 hover:bg-yellow-500 hover:border-yellow-200 hover:text-black rounded-full flex items-center justify-center py-2.5 px-4 gap-2`}>
                    {isInList ? (
                        <MdOutlinePlaylistAddCheck />
                    ):(
                    <MdOutlinePlaylistAdd />
                    )}
                    
                    <span className=' text-sm'>Add To List</span>
                    </button>
                    </div>
                    </div>
                    
                <div className=' col-span-3 h-screen pt-16 pr-12'>
                    <div className='bg-gray-950 h-full rounded-t-xl border-b-0 border border-gray-700 p-6 relative overflow-y-scroll'>
                <h1 className='mb-5'>Play {Details.name}</h1>
                

                       <Link to={`/test/${IMDBId}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                               <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> AWSindStream <span className='text-xs font-normal'>(Video Ads)</span>
                        </Link>
                       <Link to={`/vidsrcctv/${IMDBId}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                               <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> VidSrcCC <span className='text-xs font-normal'>(No Ads)</span>
                        </Link>
                       <Link to={`/videosrctv/${IMDBId}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                               <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> SuperStream <span className='text-xs font-normal'>(10% POP Ads)</span>
                        </Link>
                       <Link to={`/towstreamtv/${IMDBId}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                               <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> TwoStream <span className='text-xs font-normal'>(20% POP Ads)</span>
                        </Link>
                
                    </div>
                </div>
                </div>
              
                </div>
            </div>
        }
        
        {isTrailerPlaying && (
                       <div
                         id='TrailerVideo'
                         className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50'
                         onClick={handleCloseTrailer}
                       >
                         <div className='w-[60%] h-[65vh] border border-yellow-500 rounded-2xl overflow-hidden'>
                           <iframe
                             src={`https://www.youtube.com/embed/${trailer.key}?autoplay=1`}
                             allow="autoplay"
                             allowFullScreen
                             className='w-full h-full'
                             title='trailer'
                           ></iframe>
                         </div>
                       </div>
                     )}
    </div>
);
};

export default TvDetails;

import Nav from './components/Nav'
import PopulerMovies from './components/PopulerMovies'
import TopRatedMovies from './components/TopRatedMovies'
import HomeTop from './components/HomeTop'
import PopularTV from './components/PopularTV'
import IndianMovies from './components/IndianMovies'
import { Helmet } from 'react-helmet';
import Netflix from './components/Netflix'


const Home = () => {


    return (
        <div>
    <Helmet>
        <title>Home - Plus Streams</title>
        <meta name="description" content="Watch Online Movies and TV Shows, Web Series" />
      </Helmet>
            <Nav />
            <HomeTop/>
            <PopulerMovies/>
            <PopularTV/>
            <IndianMovies/>
            <TopRatedMovies/>
            <Netflix/>
            <div className='md:mt-4 mt-20'></div>
        </div>
    )
}

export default Home

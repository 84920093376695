import { PiMonitorPlayFill } from "react-icons/pi";
import { FiSearch } from "react-icons/fi";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Search from "./Search";
import { MdMovieFilter } from "react-icons/md";
import { TbHomeFilled } from "react-icons/tb";
import { RiTv2Fill } from "react-icons/ri";
import { BiSolidBookmarkAlt } from "react-icons/bi";






const Nav = () => {
  const [scrollOpacity, setScrollOpacity] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const toggleSearchVisibility = () => {
      setIsSearchVisible(prev => !prev);
  };

  const handleCloseSearch = () => {
      setIsSearchVisible(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition >= 50) {
        setScrollOpacity(true);
      } else {
        setScrollOpacity(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    <div
      className={` md:px-12 px-4 z-30 py-2.5 fixed w-full bg-gray-950 ease-in-out duration-300 ${
        scrollOpacity ? 'bg-opacity-100' : 'bg-opacity-25'
      }`}
    >
      <div className="m-auto flex items-center justify-between">
        <Link to={'/'} className="flex items-center text-xl gap-0.5 font-medium text-white">
          <PiMonitorPlayFill className="text-3xl text-yellow-500" />
          <div className="text-base font-semibold -mt-[2px]">
           FHDstreams
            <div className="text-[0.5rem] leading-[5px] font-normal">
              The World Of Entertainment
            </div>
          </div>
        </Link>
        <div className="flex gap-3">
          <ul className="items-center gap-6 text-sm text-gray-300 hidden md:flex">
            <li className="text-gray-50 font-semibold"><Link to={'/'}>Home</Link></li>
            <li><Link to={'/movies'}>Movies</Link></li>
            <li> <Link to={'/tv'}>TV Shows</Link></li>
            <li> <Link to={'/mylist'}>My List</Link> </li>
          </ul>
          <button
          onClick={toggleSearchVisibility}
          >
            <FiSearch />
          </button>
        </div>
      </div>
    </div>
    <Search
                className={`${isSearchVisible ? 'top-10 opacity-100' : '-top-32 opacity-0'}`}
                onClose={handleCloseSearch}
            />

            <div className="block md:hidden fixed bottom-0 w-full bg-gray-950 z-50 px-4 py-2 border-t border-gray-700 rounded-t-lg">
            <div className="flex items-center justify-between">
            <Link to={'/'} className="flex items-center flex-col">
            <div className="text-3xl text-yellow-500">
            <TbHomeFilled />
            </div>
            <p className="text-xs text-white">Home</p>
            </Link>

            <Link to={'/movies'} className="flex items-center flex-col">
            <div className="text-3xl">
            <MdMovieFilter />
            </div>
            <p className="text-xs text-white">Movies</p>
            </Link>

            <Link to={'/tv'} className="flex items-center flex-col">
            <div className="text-3xl">
            <RiTv2Fill />
            </div>
            <p className="text-xs text-white">Shows</p>
            </Link>

            <Link to={'/mylist'} className="flex items-center flex-col">
            <div className="text-3xl">
            <BiSolidBookmarkAlt />
            </div>
            <p className="text-xs text-white">My List</p>
            </Link>
            </div>
            </div>
    </>
  );
};

export default Nav;

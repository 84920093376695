import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Home"
import React from "react"
import Details from "./Details"
import Player from "./Player"
import PlayTrailer from "./PlayTrailer"
import Test from "./Test"
import TvDetails from "./TvDetails"
import Movies from "./Movies"
import TvShows from "./TvShows"
import MyList from "./MyList"
import Search from "./Search"
import Vidsrcc from "./Vidsrcc"
import Vidsrcctv from "./Vidsrcctv"
import Tembedstream from "./Tembedstream"
import Superstream from "./Superstream"
import Videosrc from "./Videosrc"
import Videosrctv from "./Videosrctv"
import Towstream from "./Towstream"
import Towstreamtv from "./Towstreamtv"

function App() {

  return (
    <div>
    <BrowserRouter>
    <Routes>  
      <Route path="/" element={<Home/>} />
      <Route path="/movies" element={<Movies/>} />
      <Route path="/tv" element={<TvShows/>} />
      <Route path="/mylist" element={<MyList/>} />
      <Route path="/:id/:title" element={<Details />} />
      <Route path="/tv/:id/:title" element={<TvDetails />} />
      <Route path="/play/:link/:loading" element={<Player />} />
      <Route path="/trailer/:key/:title" element={<PlayTrailer />} />
      <Route path="/test/:imdb" element={<Test/>} />
      <Route path="/vidsrcc/:imdb" element={<Vidsrcc/>} />
      <Route path="/vidsrcctv/:imdb" element={<Vidsrcctv/>} />
      <Route path="/tembedstream/:imdb" element={<Tembedstream/>} />
      <Route path="/superstream/:imdb" element={<Superstream/>} />
      <Route path="/videosrc/:imdb" element={<Videosrc/>} />
      <Route path="/videosrctv/:imdb" element={<Videosrctv/>} />
      <Route path="/towstream/:imdb" element={<Towstream/>} />
      <Route path="/towstreamtv/:imdb" element={<Towstreamtv/>} />
      <Route path="/search/:query" element={<Search/>} />
    </Routes>
    </BrowserRouter>
    </div>
  )
}

export default App

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from './components/Nav';
import { PiMonitorPlayFill } from 'react-icons/pi';
import { Helmet } from 'react-helmet';


const MyList = () => {
  const [movies, setMovies] = useState([]);
  const [TvShows, setTvShows] = useState([]);
  const [isMovies, setisMovies] = useState(true);

  useEffect(() => {
    const moviesList = JSON.parse(localStorage.getItem('MoviesList')) || [];
    setMovies(moviesList);
  }, []);

  useEffect(() => {
    const TvShowsList = JSON.parse(localStorage.getItem('TvList')) || [];
    setTvShows(TvShowsList);
  }, []);

  return (
    <div>
      <Nav />
      <Helmet>
        <title>My List - Plus Streams</title>
        <meta name="description" content="Watch Online TV Shows Web Series" />
      </Helmet>
        <div className='text-2xl font-semibold block md:hidden pt-16 px-4'>My List</div>
      <div className='flex items-center gap-3 md:px-12 px-2 md:pt-16 pt-2'>
        <div className='text-2xl font-semibold hidden md:block'>My List</div>
        <button
         className={`${isMovies ? ' bg-yellow-500 text-black font-bold': 'bg-gradient-to-tr from-gray-700 to-gray-800'} p-2 rounded-full border border-gray-700 text-sm px-6`}
         onClick={() => setisMovies(true)}
         >Movies</button>
        <button 
        onClick={() => setisMovies(false)}
        className={`${!isMovies ? ' bg-yellow-500 text-black font-bold': 'bg-gradient-to-tr from-gray-700 to-gray-800'} p-2 rounded-full border border-gray-700 text-sm px-6`}
        >Tv Shows</button>
      </div>
    {isMovies && 
    <div id='MoviesList' className='md:px-12 px-2 mt-6'>
    <div className="grid md:grid-cols-10 grid-cols-3 gap-3">
      {movies.map((movie, index) => (
        <Link
          to={`/${movie.id}/${movie.title.replace(/\s+/g, '-')}`}
          className='w-full h-max relative'
          key={index}
        >
          <img
            className='w-full object-cover rounded-md z-10 top-0 right-0'
            loading='lazy'
            src={movie.poster} // Use the correct property from your movie object
            alt={movie.title}
          />
          <div className='w-full h-full rounded-md bg-gray-600 top-0 left-0'></div>
          <div className='absolute z-20 opacity-0 hover:opacity-100 transition-all duration-300 w-full h-full bg-black/70 top-0 right-0 backdrop-blur-sm p-2 text-center flex flex-col items-center justify-center'>
            <p className='text-xs'>{movie.title}</p>
            <PiMonitorPlayFill className='text-3xl text-yellow-500' />
          </div>
        </Link>
      ))}
    </div>
  </div>
    }
      
      {!isMovies && 
      <div id='TvShowList' className='px-12 mt-6'>
        <div className="grid grid-cols-10 gap-3">
          {TvShows.map((movie, index) => (
            <Link
              to={`/tv/${movie.id}/${movie.title.replace(/\s+/g, '-')}`}
              className='w-full h-[200px] relative'
              key={index}
            >
              <img
                className='w-full h-[200px] object-cover rounded-md absolute z-10 top-0 right-0'
                loading='lazy'
                src={movie.poster}
                alt={movie.title}
              />
              <div className='w-full h-full rounded-md bg-gray-600 absolute top-0 left-0'></div>
              <div className='absolute z-20 opacity-0 hover:opacity-100 transition-all duration-300 w-full h-full bg-black/70 top-0 right-0 backdrop-blur-sm p-2 text-center flex flex-col items-center justify-center'>
                <p className='text-xs'>{movie.title}</p>
                <PiMonitorPlayFill className='text-3xl text-yellow-500' />
              </div>
            </Link>
          ))}
        </div>
      </div>
            }

    </div>
  );
};

export default MyList;

import React from 'react'
import VideoPlayer from './components/VideoPlayer'
import { useParams } from 'react-router-dom';
const Test = () => {
  const { imdb } = useParams();

  return (
    <div className=' w-full h-screen overflow-hidden '>
      {/* <VideoPlayer url='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4' /> */}
      <iframe className='w-full h-screen overflow-hidden' src={`https://awsindstream.com/play/${imdb}?autoplay=1`} allow="autoplay" allowFullScreen ></iframe>
      {/* <iframe className='w-full h-screen overflow-hidden' src={`https://hlsplayer.click/e/met8c1eyfged/`} allow="autoplay" allowFullScreen ></iframe> */}
    </div>
  )
}

export default Test

import React from 'react';
import { useParams } from 'react-router-dom';
import VideoPlayer from './components/VideoPlayer';

const StreamingPlay = () => {

    const { link , loading } = useParams();


    return (
        <div>
            <VideoPlayer url={link} loading={loading} />
        </div>
    );
};

export default StreamingPlay;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { PiMonitorPlayFill } from "react-icons/pi";
import axios from 'axios';
import config from './config.json';
import Nav from './components/Nav';
import _ from 'lodash';
import { Helmet } from 'react-helmet';


const TvShows = () => {
  const [genres, setGenres] = useState([]);
  const [countries, setCountries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({ iso: '', name: '' });
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [searchTermLang, setSearchTermLang] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState({ iso: '', name: '' });
  const [dropdownVisibleLang, setDropdownVisibleLang] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedSortBy, setSelectedSortBy] = useState('popularity.desc');
  const [selectedGenre, setSelectedGenre] = useState('');
  const [movies, setMovies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const loader = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const genre = searchParams.get('genre') || '';
    const country = searchParams.get('country') || '';
    const language = searchParams.get('language') || '';
    const year = searchParams.get('year') || '';
    const sortBy = searchParams.get('sortBy') || 'popularity.desc';
  
    setSelectedGenre(genre);
    setSelectedCountry({ iso: country, name: '' });
    setSelectedLanguage({ iso: language, name: '' });
    setSelectedYear(year);
    setSelectedSortBy(sortBy);
  }, [location.search]);

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const response = await axios.get(`${config.TMDBapiUrl}genre/tv/list`, {
          headers: {
            Authorization: `Bearer ${config.TMDBToken}`
          },
          params: {
            api_key: config.TMDBapikey,
          }
        });
        setGenres(response.data.genres);
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchGenres();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${config.TMDBapiUrl}configuration/countries`, {
          headers: {
            Authorization: `Bearer ${config.TMDBToken}`
          },
          params: {
            api_key: config.TMDBapikey,
          }
        });
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(`${config.TMDBapiUrl}configuration/languages`, {
          headers: {
            Authorization: `Bearer ${config.TMDBToken}`
          },
          params: {
            api_key: config.TMDBapikey,
          }
        });
        setLanguages(response.data);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchLanguages();
  }, []);

  const fetchMovies = async (page) => {
    try {
      const response = await axios.get(`${config.TMDBapiUrl}discover/tv`, {
        headers: {
          Authorization: `Bearer ${config.TMDBToken}`
        },
        params: {
          api_key: config.TMDBapikey,
          page,
          with_genres: selectedGenre,
          with_original_language: selectedLanguage.iso,
          with_origin_country: selectedCountry.iso,
          first_air_date_year: selectedYear,
          sort_by: selectedSortBy,
          without_watch_providers: false,
          include_adult: false,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching movies:', error);
    }
  };

  const loadInitialMovies = async () => {
    try {
      setIsLoading(true);
      const data1 = await fetchMovies(1);
      const data2 = await fetchMovies(2);
      setMovies([...data1.results, ...data2.results]);
      setTotalPages(Math.max(data1.total_pages, data2.total_pages));
      setCurrentPage(2);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading initial movies:', error);
      setIsLoading(false);
    }
  };
  

  const loadMoreMovies = async () => {
    if (isLoading || currentPage >= totalPages) return;

    try {
      setIsLoading(true);
      const data = await fetchMovies(currentPage + 1);
      setMovies(prevMovies => [...prevMovies, ...data.results]);
      setCurrentPage(prevPage => prevPage + 1);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading more movies:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInitialMovies();
  }, [selectedGenre, selectedLanguage, selectedCountry, selectedYear, selectedSortBy]);

  const filteredCountries = countries.filter(country =>
    country.english_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
    
  const filteredLanguages = languages.filter(language =>
    language.english_name.toLowerCase().includes(searchTermLang.toLowerCase())
  );

  const updateQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach(key => {
      if (params[key]) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    });
    navigate(`?${searchParams.toString()}`);
  };

  const handleCountryClick = (country) => {
    setSelectedCountry({ iso: country.iso_3166_1, name: country.english_name });
    setSearchTerm(country.english_name);
    setDropdownVisible(false);
    updateQueryParams({ country: country.iso_3166_1 });
  };

  const handleLanguageClick = (language) => {
    setSelectedLanguage({ iso: language.iso_639_1, name: language.english_name });
    setSearchTermLang(language.english_name);
    setDropdownVisibleLang(false);
    updateQueryParams({ language: language.iso_639_1 });
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    updateQueryParams({ year });
  };

  const handleSortByChange = (sortBy) => {
    setSelectedSortBy(sortBy);
    updateQueryParams({ sortBy });
  };

  const handleGenreChange = (genre) => {
    setSelectedGenre(genre);
    updateQueryParams({ genre });
  };

  const debouncedHandleScroll = useCallback(_.debounce(() => {
    if (loader.current) {
      const bottom = loader.current.getBoundingClientRect().bottom - window.innerHeight;
      if (bottom < 3 && currentPage < totalPages && !isLoading) {
        loadMoreMovies();
      }
    }
  }, 300), [currentPage, totalPages, isLoading]);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [debouncedHandleScroll]);

  const getYears = (startYear, endYear) => {
    const years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    return years;
  };

  const currentYear = new Date().getFullYear();
  const years = getYears(1960, currentYear);

  const customSelectStyle = {
    position: 'absolute',
    top: '50%',
    right: '16px',
    transform: 'translateY(-50%)',
    width: '20px',
    height: '20px',
    pointerEvents: 'none', // Ignore mouse events
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M6 9l6 6 6-6'></path></svg>")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  };

  return (
    <div>
      <Nav />
      <Helmet>
        <title>TV Shows - Plus Streams</title>
        <meta name="description" content="Watch Online TV Shows Web Series" />
      </Helmet>
      <div className='md:px-12 px-1 pt-16'>
          <div className='text-2xl font-semibold md:hidden block mb-2 px-4'>TV Shows</div>
        <form className='flex items-center gap-2 flex-wrap'>
          <div className='text-2xl font-semibold md:block hidden'>TV Shows</div>
          <div className='relative md:my-2 w-32 md:w-max'>
            <select
              className="custom-select pr-8 w-full bg-gradient-to-tr from-gray-700 to-gray-800 p-2 pl-4 rounded-full border border-gray-700 text-sm"
              value={selectedGenre}
              onChange={(e) => handleGenreChange(e.target.value)}
            >
              <option className='bg-gray-950' value="">Genres</option>
              {genres.map(genre => (
                <option key={genre.id} className='bg-gray-950' value={genre.id}>{genre.name}</option>
              ))}
            </select>
            <div style={customSelectStyle}></div>
          </div>

          <div className='relative md:my-2'>
            <input
              type="text"
              className="w-full pr-8 bg-gradient-to-tr from-gray-700 to-gray-800 p-2 pl-4 rounded-full border border-gray-700 text-sm text-white"
              placeholder="Select Countries"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setDropdownVisible(true)} // Show the dropdown when input is focused
              onBlur={() => setTimeout(() => setDropdownVisible(false), 100)} // Hide the dropdown after a short delay when input is blurred
            />
            {dropdownVisible && (
              <div className="absolute w-full mt-2 max-h-80 overflow-y-auto bg-gray-950 border border-gray-700 z-30 rounded-md shadow-lg">
                {filteredCountries.map(country => (
                  <div
                    key={country.iso_3166_1}
                    className="py-0.5 px-2 text-sm hover:bg-gray-600 text-white cursor-pointer"
                    onMouseDown={(e) => e.preventDefault()} // Prevent the input from losing focus
                    onClick={() => handleCountryClick(country)}
                  >
                    {country.english_name}
                  </div>
                ))}
              </div>
            )}
            <div style={customSelectStyle}></div>
          </div>

          <div className='relative md:my-2'>
            <input
              type="text"
              className="w-full pr-8 bg-gradient-to-tr from-gray-700 to-gray-800 p-2 pl-4 rounded-full border border-gray-700 text-sm text-white"
              placeholder="Select Languages"
              value={searchTermLang}
              onChange={(e) => setSearchTermLang(e.target.value)}
              onFocus={() => setDropdownVisibleLang(true)} // Show the dropdown when input is focused
              onBlur={() => setTimeout(() => setDropdownVisibleLang(false), 100)} // Hide the dropdown after a short delay when input is blurred
            />
            {dropdownVisibleLang && (
              <div className="absolute w-full mt-2 max-h-80 overflow-y-auto bg-gray-950 border border-gray-700 z-30 rounded-md shadow-lg">
                {filteredLanguages.map(language => (
                  <div
                    key={language.iso_639_1}
                    className=" py-0.5 px-2 text-sm hover:bg-gray-600 text-white cursor-pointer"
                    onMouseDown={(e) => e.preventDefault()} // Prevent the input from losing focus
                    onClick={() => handleLanguageClick(language)}
                  >
                    {language.english_name}
                  </div>
                ))}
              </div>
            )}
            <div style={customSelectStyle}></div>
          </div>

          <div className='relative md:my-2'>
            <select
              className="custom-select pr-8 text-white w-full bg-gradient-to-tr from-gray-700 to-gray-800 p-2 pl-4 rounded-full border border-gray-700 text-sm"
              value={selectedYear}
              onChange={(e) => handleYearChange(e.target.value)}
            >
              <option className='bg-gray-950' value="">Year</option>
              {years.map(year => (
                <option className='bg-gray-950' key={year} value={year}>{year}</option>
              ))}
            </select>
            <div style={customSelectStyle}></div>
          </div>

          <div className='relative md:my-2 hidden md:block'>
            <select
              className="custom-select pr-8 text-white w-full bg-gradient-to-tr from-gray-700 to-gray-800 p-2 pl-4 rounded-full border border-gray-700 text-sm"
              value={selectedSortBy}
              onChange={(e) => handleSortByChange(e.target.value)}
            >
              <option className='bg-gray-950' value="default">Sort By</option>
              <option className='bg-gray-950' value="original_title.asc">original_title.asc</option>
              <option className='bg-gray-950' value="original_title.desc">original_title.desc</option>
              <option className='bg-gray-950' value="popularity.asc">popularity.asc</option>
              <option className='bg-gray-950' value="popularity.desc">popularity.desc</option>
              <option className='bg-gray-950' value="revenue.asc">revenue.asc</option>
              <option className='bg-gray-950' value="revenue.desc">revenue.desc</option>
              <option className='bg-gray-950' value="primary_release_date.asc">primary_release_date.asc</option>
              <option className='bg-gray-950' value="title.asc">title.asc</option>
              <option className='bg-gray-950' value="title.desc">title.desc</option>
              <option className='bg-gray-950' value="primary_release_date.desc">primary_release_date.desc</option>
              <option className='bg-gray-950' value="vote_average.asc">vote_average.asc</option>
              <option className='bg-gray-950' value="vote_average.desc">vote_average.desc</option>
              <option className='bg-gray-950' value="vote_count.asc">vote_count.asc</option>
              <option className='bg-gray-950' value="vote_count.desc">vote_count.desc</option>
            </select>
            <div style={customSelectStyle}></div>
          </div>
        </form>
      </div>

      <div className='md:px-12 px-2 mt-6'>
        <div className="grid md:grid-cols-10 grid-cols-3 md:gap-3 gap-2">
          {movies.map((movie, index) => (
            <Link
              to={`/tv/${movie.id}/${movie.name.replace(/\s+/g, '-')}`}
              className='w-full h-max relative'
              key={index}
            >
              <img
                className='w-full object-cover rounded-md z-10 top-0 right-0'
                loading='lazy'
                src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`}
                alt={movie.name}
              />
              <div className='w-full h-full rounded-md bg-gray-600 top-0 left-0'></div>
              <div className='absolute z-20 opacity-0 hover:opacity-100 transition-all duration-300 w-full h-full bg-black/70 top-0 right-0 backdrop-blur-sm p-2 text-center flex flex-col items-center justify-center'>
                <p className='text-xs'>{movie.name}</p>
                <PiMonitorPlayFill className='text-3xl text-yellow-500' />
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Loader to indicate loading */}
      {currentPage < totalPages && (
        <div ref={loader} className="text-center py-4">
          <span>Loading...</span>
        </div>
      )}

    </div>
  );
}

export default TvShows;

import React from 'react'
import { useParams } from 'react-router-dom';

const Towstreamtv = () => {
    const { imdb } = useParams()
  return (
    <div className=' w-full h-screen overflow-hidden'>
        <iframe className='w-full h-screen overflow-hidden' title='streaming' src={`https://www.2embed.cc/embedtvfull/${imdb}`} allow="autoplay" allowFullScreen ></iframe>
    </div>
  )
}

export default Towstreamtv

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../config.json';
import { FaPlay } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";
import { PiVideoBold } from "react-icons/pi";



const HomeTop = () => {
    const [CustomDiscover, setCustomDiscover] = useState([]);
    const [randomMovie, setRandomMovie] = useState(null);
    const [Images, setImages] = useState(null);
    const [Videos, setVideos] = useState([]);
    const [isTrailerPlaying, setIsTrailerPlaying] = useState(false);

    const handlePlayTrailer = () => {
      setIsTrailerPlaying(true);
    };
  
    const handleCloseTrailer = (event) => {
      if (event.target.id === 'TrailerVideo') {
        setIsTrailerPlaying(false);
      }
    };



    useEffect(() => {
        const fetchCustomDiscover = async () => {
            try {
                const response = await axios.get(`${config.TMDBapiUrl}trending/movie/week`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
                setCustomDiscover(response.data.results);
            } catch (error) {
                console.error('Error fetching popular movies:', error);
            }
        };

        fetchCustomDiscover();
    }, []);

    useEffect(() => {
        if (CustomDiscover.length > 0 && randomMovie === null) {
            const randomIndex = Math.floor(Math.random() * CustomDiscover.length);
            setRandomMovie(CustomDiscover[randomIndex]);
        }
    }, [CustomDiscover, randomMovie]);

    useEffect(() => {
        if (randomMovie !== null) {
            console.log(randomMovie);
            const fetchImages = async () => {
                try {
                    const response = await axios.get(`${config.TMDBapiUrl}movie/${randomMovie.id}/images`, {
                        headers: {
                            Authorization: `Bearer ${config.TMDBToken}`
                        },
                        params: {
                            api_key: config.TMDBapikey
                        }
                    });
                    const images = response.data.logos;  
                    const filteredImages = images.filter(image => image.iso_639_1 === 'en');
                    // Get a single result from the filtered list
                    const singleImage = filteredImages.length > 0 ? filteredImages[0] : null;

                    setImages(singleImage);
                } catch (error) {
                    console.error('Error fetching item Images', error);
                }
            };

            fetchImages();
        }
    }, [randomMovie]);

    

    useEffect(() => {
        if (randomMovie !== null) {
            console.log(randomMovie);
            const fetchVideos = async () => {
                try {
                    const response = await axios.get(`${config.TMDBapiUrl}movie/${randomMovie.id}/videos`, {
                        headers: {
                            Authorization: `Bearer ${config.TMDBToken}`
                        },
                        params: {
                            api_key: config.TMDBapikey
                        }
                    });
                    // Step 1: Try to find an official trailer
                const officialTrailers = response.data.results.filter(video => video.official && video.name.toLowerCase().includes('official trailer'));
                
                // Step 2: If no official trailers, look for any trailer
                const trailers = officialTrailers.length > 0 ? officialTrailers : response.data.results.filter(video => video.name.toLowerCase().includes('trailer'));
                
                // Step 3: If no trailers at all, pick a random video
                const officialVideos = trailers.length > 0 ? trailers[0] : (response.data.results.length > 0 ? response.data.results[0] : null);
    
                    if (officialVideos.length > 1) {
                        setVideos(officialVideos[0]);
                        console.log(officialVideos[0]);
                    } else {
                        setVideos(officialVideos);
                        console.log(officialVideos);
                    }
    
                } catch (error) {
                    console.error('Error fetching item Video', error);
                }
            };
    
            fetchVideos();
        }
    }, [randomMovie]);
    
    console.log('check',Videos);


    
    const [isInList, setIsInList] = useState(false);

    useEffect(() => {
        if(randomMovie){
            const existingMoviesList = JSON.parse(localStorage.getItem('MoviesList')) || [];
            const movieExists = existingMoviesList.some(movie => movie.id === randomMovie.id);
            setIsInList(movieExists);
        }
    }, [randomMovie]);

    const handleMoviesList = () => {
        const existingMoviesList = JSON.parse(localStorage.getItem('MoviesList')) || [];
    
        if (isInList) {
          const updatedMoviesList = existingMoviesList.filter(movie => movie.id !== randomMovie.id);
          localStorage.setItem('MoviesList', JSON.stringify(updatedMoviesList));
          setIsInList(false);
        } else {
          const movieDetails = {
            title: randomMovie.title,
            id: randomMovie.id,
            backdrop: `https://image.tmdb.org/t/p/original/${randomMovie.backdrop_path}`,
            poster: `https://image.tmdb.org/t/p/original/${randomMovie.poster_path}`,
          };
          const updatedMoviesList = [...existingMoviesList, movieDetails];
          localStorage.setItem('MoviesList', JSON.stringify(updatedMoviesList));
          setIsInList(true); 
        }
      };




    return (
        <div className='group relative'>
            <div className='md:px-12 px-2 bg-gradient-to-tr from-gray-950/70 to-gray-50/0 absolute w-full md:h-screen h-[30rem]'>
                <div className='absolute md:bottom-60 bottom-40'>
                    {Images && (
                        <img className=' md:h-28 h-20 md:w-[27rem] w-[10rem] object-contain object-left' src={`https://image.tmdb.org/t/p/original/${Images.file_path}`} alt="" />
                    )}
                    {randomMovie && (
                        <div className='w-1/3 text-sm my-5'>
                            <p className='font-bold mb-2 hidden md:block'>{randomMovie.release_date}</p>
                            <p className='font-light hidden md:block'>{randomMovie.overview}</p>
                        </div>
                    )}
                    {Images && (
                        <div className='flex md:gap-2 gap-1 items-center'>
                          
                            {Videos && 
                     <div>
                     <button
                       className='md:text-2xl text-xl bg-gray-900/30 md:h-12 md:w-12 h-10 w-10 border border-gray-400/50 hover:bg-yellow-500 hover:border-yellow-200 hover:text-black rounded-full flex items-center justify-center'
                       onClick={handlePlayTrailer}
                     >
                       <PiVideoBold />
                     </button>
               
                     {isTrailerPlaying && (
                       <div
                         id='TrailerVideo'
                         className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50'
                         onClick={handleCloseTrailer}
                       >
                         <div className='md:w-[60%] md:h-[65vh] w-[97%] h-[40vh] border border-yellow-500 rounded-2xl overflow-hidden'>
                           <iframe
                             src={`https://www.youtube.com/embed/${Videos.key}?autoplay=1`}
                             allow="autoplay"
                             allowFullScreen
                             className='w-full h-full'
                             title='trailer'
                           ></iframe>
                         </div>
                       </div>
                     )}
                   </div>
                    
                    }
                    <button 
                    className={` ${isInList ? 'bg-yellow-500 text-black':'bg-gray-900/30'} md:text-2xl text-xl  md:h-12 md:w-12 h-10 w-10 border border-gray-400/50 hover:bg-yellow-500 hover:border-yellow-200 hover:text-black rounded-full flex items-center justify-center`}
                    onClick={handleMoviesList}
                    >
                       {isInList ? (
                        <MdOutlinePlaylistAddCheck />
                    ):(
                    <MdOutlinePlaylistAdd />
                    )}
                    
                    </button>
                            <Link to={`/${randomMovie.id}/${randomMovie.title.replace(/\s+/g, '-')}`} className='md:text-xl text-lg flex items-center gap-2 bg-gray-900/30 border border-gray-400/50 rounded-full hover:bg-yellow-500 hover:border-yellow-200 hover:text-black'><span className='pl-4 md:text-lg text-base'>Show</span> <span className='bg-yellow-500 rounded-full md:h-12 md:w-12 h-10 w-10 flex items-center justify-center'><FaPlay /></span></Link>
                        </div>
                    )}
                </div>
            </div>
            {randomMovie && (
                <img className='w-full md:h-screen h-[30rem] object-cover' src={`https://image.tmdb.org/t/p/original/${randomMovie.backdrop_path}`} alt={randomMovie.title} />
            )}
        </div>
    );
};

export default HomeTop;

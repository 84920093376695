import React from 'react'
import { useParams } from 'react-router-dom';

const Superstream = () => {
    const { imdb } = useParams();
  return (
    <div className=' w-full h-screen overflow-hidden'>
    <iframe className='w-full h-screen overflow-hidden' title='streaming' src={`https://multiembed.mov/?video_id=${imdb}`} allow="autoplay" allowFullScreen ></iframe>
  </div>
  )
}

export default Superstream

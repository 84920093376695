import React from 'react'
import { useParams } from 'react-router-dom';

const Videosrctv = () => {
    const { imdb } = useParams()
  return (
    <div className=' w-full h-screen overflow-hidden'>
        <iframe className='w-full h-screen overflow-hidden' title='streaming' src={`https://vidsrc.xyz/embed/tv/${imdb}?autoplay=1`} allow="autoplay" allowFullScreen ></iframe>
    </div>
  )
}

export default Videosrctv

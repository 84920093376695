import React from 'react'
import { useParams } from 'react-router-dom';

const Vidsrcctv = () => {
    const { imdb } = useParams();
    return (
      <div className=' w-full h-screen overflow-hidden'>
        <iframe className='w-full h-screen overflow-hidden' title='streaming' src={`https://vidsrc.cc/v2/embed/tv/${imdb}?autoplay=1`} allow="autoplay" allowFullScreen ></iframe>
      </div>
  )
}

export default Vidsrcctv

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import config from '../config.json';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowForward  } from "react-icons/io";
import { MdOutlineArrowCircleLeft, MdOutlineArrowCircleRight  } from "react-icons/md";
import { PiMonitorPlayFill } from "react-icons/pi";

const TopRatedMovies = () => {

    const [IndianMovies, setIndianMovies] = useState([]);
    

    useEffect(() => {
        const fetchIndianMovies = async () => {
            try {
                const randomPage1 = 1;
                const randomPage2 = 2;
                const randomPage3 = 3;
    
                const response1 = await axios.get(`${config.TMDBapiUrl}discover/movie?include_adult=false&include_video=false&language=en-US&page=${randomPage1}&sort_by=popularity.desc&with_origin_country=IN&with_original_language=pa&year=2024`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
    
                const response2 = await axios.get(`${config.TMDBapiUrl}discover/movie?include_adult=false&include_video=false&language=en-US&page=${randomPage2}&sort_by=popularity.desc&with_origin_country=IN&with_original_language=pa&year=2024`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
    
                const response3 = await axios.get(`${config.TMDBapiUrl}discover/movie?include_adult=false&include_video=false&language=en-US&page=${randomPage3}&sort_by=popularity.desc&with_origin_country=IN&with_original_language=pa&year=2024`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
    
                // Combine the results from all three pages
                const combinedResults = [...response1.data.results, ...response2.data.results, ...response3.data.results];
    
                setIndianMovies(combinedResults);
            } catch (error) {
                console.error('Error fetching Indian Movies Latest:', error);
            }
        };
    
        fetchIndianMovies();
    }, []);
    
  

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 10
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 10
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 5
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3
        }
      };

      const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        return (
          <div className="carousel-button-group absolute right-0 top-0">
            <button className=' ' onClick={() => previous()}><MdOutlineArrowCircleLeft className=' text-3xl' /></button>
            <button className=' ' onClick={() => next()}><MdOutlineArrowCircleRight className=' text-3xl' /></button>
          </div>
        );
      };

  return (
    <div className='group mt-10'>
            <div className='md:px-12 px-1 flex md:items-end items-center gap-2 -mb-8  relative z-10 w-max'>
            <h1 className=' md:text-3xl text-xl relative z-10 pr-1'>Punjabi Movies</h1>
            <Link to={'/movies?language=pa'} className='flex items-center absolute right-0 opacity-0 group-hover:opacity-100 md:group-hover:-right-20 group-hover:-right-[5.6rem] md:pt-0 pt-1 text-yellow-400 ease-in-out duration-300 md:text-base text-xs'>Explore More <IoIosArrowForward /></Link>
            </div>
            
            <Carousel
            className='pt-10 md:mx-12'
            itemClass=" p-1"
            infinite={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            responsive={responsive}>
            {IndianMovies.map((movie, index) => (
                    <Link to={`/${movie.id}/${movie.title.replace(/\s+/g, '-')}`} className='w-[10.1%] relative' key={index}>
                        <img className=' w-ful rounded-md' src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`} alt="" />
                        <div className=' absolute opacity-0 hover:opacity-100 transition-all duration-300 w-full h-full bg-black/70 top-0 right-0 backdrop-blur-sm p-2 text-center flex flex-col items-center justify-center'> <p className='text-xs'>{movie.title}</p>
                        <PiMonitorPlayFill className=' text-3xl text-yellow-500'/>
                        </div>
                    </Link>
                ))}
            </Carousel>
            </div>
  )
}

export default TopRatedMovies

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FaPlay, FaPause, FaArrowLeft } from "react-icons/fa";
import { TbRewindForward15, TbRewindBackward15 } from "react-icons/tb";
import { IoVolumeHigh, IoVolumeMedium, IoVolumeLow, IoVolumeOff } from "react-icons/io5";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import { MdSpeed } from "react-icons/md";
import { RiPictureInPicture2Fill, RiPictureInPictureExitFill } from "react-icons/ri";
import Hls from 'hls.js';

const VideoPlayer = ({ url, loading }) => {
  const videoRef = useRef(null);
  const volumeRef = useRef(null);
  const controlsRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [isMuted, setIsMuted] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isPip, setIsPip] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const [isPlayBackOpen, setisPlayBackOpen] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const animationFrameId = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const hls = useRef(null);

  const updateProgress = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
      const bufferedTime = video.buffered.length ? video.buffered.end(video.buffered.length - 1) : 0;
      setBuffered(bufferedTime);
      animationFrameId.current = requestAnimationFrame(updateProgress);
    }
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    const handleWaiting = () => setIsLoading(true);
    const handlePlaying = () => setIsLoading(false);

    if (Hls.isSupported() && url.endsWith(".m3u8")) {
      hls.current = new Hls();
      hls.current.loadSource(url);
      hls.current.attachMedia(video);
      hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
        setDuration(video.duration);
        setIsLoading(false); // Hide loading image once the video is ready
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = url;
      video.addEventListener('loadedmetadata', () => {
        setDuration(video.duration);
        setIsLoading(false); // Hide loading image once the video is ready
      });
    } else {
      video.src = url;
      video.addEventListener('loadedmetadata', () => {
        setDuration(video.duration);
        setIsLoading(false); // Hide loading image once the video is ready
      });
    }

    video.addEventListener('waiting', handleWaiting);
    video.addEventListener('playing', handlePlaying);
    video.addEventListener('loadedmetadata', () => {
      setDuration(video.duration);
    });
    animationFrameId.current = requestAnimationFrame(updateProgress);

    return () => {
      if (hls.current) {
        hls.current.destroy();
      }
      video.removeEventListener('waiting', handleWaiting);
      video.removeEventListener('playing', handlePlaying);
      video.removeEventListener('loadedmetadata', () => {
        setDuration(video.duration);
      });
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [url, updateProgress]);

  useEffect(() => {
    videoRef.current.volume = volume;
    videoRef.current.muted = isMuted;
  }, [volume, isMuted]);

  useEffect(() => {
    videoRef.current.playbackRate = playbackRate;
  }, [playbackRate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (controlsRef.current && !controlsRef.current.contains(event.target)) {
        setisPlayBackOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let timer;
    const resetTimer = () => {
      clearTimeout(timer);
      setShowControls(true);
      timer = setTimeout(() => {
        if (isPlaying) {
          setShowControls(false);
        }
      }, 3000);
    };
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('click', resetTimer);
    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('click', resetTimer);
    };
  }, [isPlaying]);


  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const skipTime = (amount) => {
    videoRef.current.currentTime += amount;
  };

  const changeVolume = useCallback((amount) => {
    let newVolume = Math.min(1, Math.max(0, volume + amount));
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  }, [volume]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const togglePip = async () => {
    if (videoRef.current !== document.pictureInPictureElement) {
      try {
        await videoRef.current.requestPictureInPicture();
        setIsPip(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await document.exitPictureInPicture();
        setIsPip(false);
      } catch (error) {
        console.error(error);
      }
    }
  };


  
  useEffect(() => {
    const handleKeyPress = (event) => {
      switch (event.code) {
        case 'Space':
          togglePlay();
          break;
        case 'ArrowRight':
          skipTime(15);
          break;
        case 'ArrowLeft':
          skipTime(-15);
          break;
        case 'ArrowUp':
          changeVolume(0.1);
          break;
        case 'ArrowDown':
          changeVolume(-0.1);
          break;
        case 'KeyF':
          toggleFullscreen();
          break;
        case 'ControlPeriod':
          togglePip();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [changeVolume]);


  const toggleFullscreen = () => {
    const elem = document.getElementById('VideoContainer');
    if (!document.fullscreenElement) {
      elem.requestFullscreen().then(() => setIsFullscreen(true)).catch((err) => console.error(err));
    } else {
      document.exitFullscreen().then(() => setIsFullscreen(false)).catch((err) => console.error(err));
    }
  };

  const handlePlaybackSpeed = (speed) => {
    setPlaybackRate(speed);
  };

  const handleVolumeChange = (e) => {
    const rect = volumeRef.current.getBoundingClientRect();
    const posX = e.clientX - rect.left;
    const volume = posX / rect.width;
    setVolume(volume);
  };

  const handleVideoProgressChange = (e) => {
    const rect = e.target.getBoundingClientRect();
    const posX = e.clientX - rect.left;
    const newTime = (posX / rect.width) * duration;
    videoRef.current.currentTime = newTime;
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return `${h > 0 ? `${h}:` : ''}${m}:${s < 10 ? `0${s}` : s}`;
  };

  const handlePlayBackDialog = () => {
    setisPlayBackOpen((prev) => !prev);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div
      id="VideoContainer"
      className="w-full h-screen relative bg-black"
      onMouseMove={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(isPlaying ? false : true)}
      onDoubleClick={toggleFullscreen}
    >

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 backdrop-blur-md z-30">
          {loading.includes("https://image.tmdb.org") ? (
            <img src={loading} alt="Loading..." className="h-[7rem] w-[30rem] object-contain blink" />
          ) : (
            <p className='text-4xl font-bold blink'>{loading}</p>
          )}
        </div>
      )}

      <div id="VideoControlsTop" className={`absolute top-0 left-0 z-50 p-5 w-full ${showControls ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
        <button onClick={goBack} className="text-2xl"><FaArrowLeft /></button>
      </div>
      <div id="videoControlsBottom" className={`absolute bottom-0 left-0 z-50 p-5 w-full bg-gradient-to-t to-gray-50/0 pt-24 from-black ${showControls ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
        <div className='w-full rounded h-1 bg-gray-500 mb-5 relative cursor-pointer group hover:h-2'>
          <div id="videoProgress" className="w-full rounded h-1 bg-transparent mb-5 absolute group-hover:h-2 top-0 left-0 cursor-pointer z-30" onMouseDown={handleVideoProgressChange}></div>
          <div id="videoProgressRendered" className="rounded h-1 bg-gray-300 mb-5 absolute group-hover:h-2 top-0 left-0" style={{ width: `${(buffered / duration) * 100}%` }}></div>
          <div id="videoProgressFilled" className="rounded h-1 bg-yellow-500 mb-5 absolute group-hover:h-2 top-0 left-0" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
        </div>
        <div className="flex items-center justify-between ">
          <div className="flex items-center gap-4">
            <button className="text-2xl" onClick={togglePlay}>{isPlaying ? <FaPause /> : <FaPlay />}</button>
            <button className="text-3xl" onClick={() => skipTime(-15)}><TbRewindBackward15 /></button>
            <button className="text-3xl" onClick={() => skipTime(15)}><TbRewindForward15 /></button>
            <div className="group flex items-center gap-3">
              <button className="text-3xl" onClick={toggleMute}>
                {isMuted ? <IoVolumeOff /> : volume > 0.55 ? <IoVolumeHigh /> : volume > 0.25 ? <IoVolumeMedium /> : <IoVolumeLow />}
              </button>
              <div id="volumeProgress" className="w-[10rem] bg-gray-300 rounded-full h-1.5 cursor-pointer hidden group-hover:block" onClick={handleVolumeChange} ref={volumeRef}>
                <div id="volumeProgressRendered" className="rounded h-1.5 bg-yellow-500 mb-5" style={{ width: `${volume * 100}%` }}></div>
              </div>
            </div>
            <div><span id="RemainingVideoDuration">{formatTime(currentTime)}</span> / <span id="TotalVideoDuration">{formatTime(duration)}</span></div>
          </div>
          <div className="flex items-center gap-4">
            <button className="text-3xl relative" ref={controlsRef}>
              <MdSpeed onClick={handlePlayBackDialog} />
              <div id="PlayBackSpeed" className={`absolute bottom-8 right-1 w-40 bg-gray-900/80 text-left p-3 rounded-md ${isPlayBackOpen ? 'block' : ' hidden'}`}>
                <p className="text-sm font-semibold">Playback Speed</p>
                <ul className="text-sm flex flex-col gap-2.5 mt-3">
                  {[0.5, 0.75, 1, 1.25, 1.5].map(speed => (
                    <li key={speed} className={`${speed === playbackRate ? 'text-yellow-500 font-semibold' : ''}`} onClick={() => handlePlaybackSpeed(speed)}>{`${speed}x`}</li>
                  ))}
                </ul>
              </div>
            </button>
            <button className="text-3xl" onClick={togglePip}>{isPip ? <RiPictureInPictureExitFill /> : <RiPictureInPicture2Fill />}</button>
            <button className="text-3xl" onClick={toggleFullscreen}>{isFullscreen ? <BiExitFullscreen /> : <BiFullscreen />}</button>
          </div>
        </div>
      </div>
      <video
        onClick={togglePlay}
        ref={videoRef}
        className="w-full h-screen"
        preload="auto"
        autoPlay
        onPlay={() => setIsPlaying(true)} // Update isPlaying state when video starts playing
        onPause={() => setIsPlaying(false)} // Update isPlaying state when video pauses
        onLoadedData={() => setIsLoading(false)}
        onWaiting={() => setIsLoading(true)}
        onPlaying={() => setIsLoading(false)}
      ></video>
    </div>
  );
};

export default VideoPlayer;

import React, { useEffect, useState } from 'react';
import { Link, useParams, } from 'react-router-dom';
import Nav from './components/Nav';
import config from './config.json';
import axios from 'axios';
import { PiVideoBold } from "react-icons/pi";
import { FaPlay } from "react-icons/fa";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";
import { Helmet } from 'react-helmet';
import { IoCloseCircle } from "react-icons/io5";





const Details = () => {
    const { id } = useParams();
    const [Details, setDetails] = useState(null);
    const [MovieLogo, setMovieLogo] = useState([]);
    const [trailer, setTrailer] = useState(null);
    const [isTrailerPlaying, setIsTrailerPlaying] = useState(false);
    const [isLinksVisible, setIsLinksVisible] = useState(false);

    // Function to show Links div
    const showLinks = () => {
        setIsLinksVisible(true);
    };

    // Function to hide Links div
    const hideLinks = () => {
        setIsLinksVisible(false);
    };


    const handlePlayTrailer = () => {
      setIsTrailerPlaying(true);
    };
  
    const handleCloseTrailer = (event) => {
      if (event.target.id === 'TrailerVideo') {
        setIsTrailerPlaying(false);
      }
    };

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axios.get(`${config.TMDBapiUrl}movie/${id}`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
                setDetails(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching item details', error);
            }
        };

        fetchDetails();
    }, [id]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${config.TMDBapiUrl}movie/${id}/images`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
                const images = response.data.logos;
                // Filter images with iso_639_1: 'en'
                const filteredImages = images.filter(image => image.iso_639_1 === 'en');
                // Get a single result from the filtered list or fallback to the first image
                const singleImage = filteredImages.length > 0 ? filteredImages[0] : (images.length > 0 ? images[0] : null);
                setMovieLogo(singleImage);
            } catch (error) {
                console.error('Error fetching images', error);
            }
        };
    
        fetchImages();
    }, [id]);
    
    
    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(`${config.TMDBapiUrl}movie/${id}/videos`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        api_key: config.TMDBapikey
                    }
                });
                console.log(response.data.results);
    
                // Step 1: Try to find an official trailer
                const officialTrailers = response.data.results.filter(video => video.official && video.name.toLowerCase().includes('official trailer'));
                
                // Step 2: If no official trailers, look for any trailer
                const trailers = officialTrailers.length > 0 ? officialTrailers : response.data.results.filter(video => video.name.toLowerCase().includes('trailer'));
                
                // Step 3: If no trailers at all, pick a random video
                const selectedVideo = trailers.length > 0 ? trailers[0] : (response.data.results.length > 0 ? response.data.results[0] : null);
                
                setTrailer(selectedVideo);
                console.log('video trailer', selectedVideo);
            } catch (error) {
                console.error('Error fetching videos', error);
            }
        };
        fetchVideos();
    }, [id]);
    
      
    






      const [isInList, setIsInList] = useState(false);

        useEffect(() => {
            if(Details){
                const existingMoviesList = JSON.parse(localStorage.getItem('MoviesList')) || [];
                const movieExists = existingMoviesList.some(movie => movie.id === Details.id);
                setIsInList(movieExists);
            }
        }, [Details]);

        const handleMoviesList = () => {
            const existingMoviesList = JSON.parse(localStorage.getItem('MoviesList')) || [];
        
            if (isInList) {
              const updatedMoviesList = existingMoviesList.filter(movie => movie.id !== Details.id);
              localStorage.setItem('MoviesList', JSON.stringify(updatedMoviesList));
              setIsInList(false);
            } else {
              const movieDetails = {
                title: Details.title,
                id: Details.id,
                backdrop: `https://image.tmdb.org/t/p/original/${Details.backdrop_path}`,
                poster: `https://image.tmdb.org/t/p/original/${Details.poster_path}`,
              };
              const updatedMoviesList = [...existingMoviesList, movieDetails];
              localStorage.setItem('MoviesList', JSON.stringify(updatedMoviesList));
              setIsInList(true); 
            }
          };



    return (
        <div>
            <Nav />

        {Details &&
            <div className='w-full md:h-screen bg-cover bg-opacity-50' style={{backgroundImage: `url(https://image.tmdb.org/t/p/original/${Details?.backdrop_path})`}}>
                <Helmet>
                    <title>{Details.title} - Plus Streams</title>
                    <meta name="description" content={`${Details.overview}`} />
                </Helmet>
                <div className='w-full md:h-screen bg-gray-950/50 backdrop-blur-sm'>
                <div className=' grid md:grid-cols-10 grid-cols-1'>
                <div className=' md:col-span-7 md:pl-12 pl-2 pt-16 flex justify-center flex-col'>
                {MovieLogo ? 
                  (<img className=' h-[7rem] w-[30rem] object-contain object-left' src={`https://image.tmdb.org/t/p/original/${MovieLogo.file_path}`} alt="" />):
                  (
                    <p className='text-4xl font-bold'>{Details.title}</p>
                  )
                  }
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-5 mb-1'>Status</div>
                    <div className='flex items-center gap-3'>
                    <div className=' bg-gray-800/30 text-sm border border-gray-700 text-gray-200 py-1.5 px-3 rounded-full'>{Details.status}</div>
                    <div className=' bg-gray-800/30 text-sm border border-gray-700 text-gray-200 py-1.5 px-3 rounded-full'>{Details.release_date}</div>
                    </div>
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-3 mb-1'>Genres</div>
                    <div className='flex items-center gap-3'>
                    {Details.genres.map((genre, index) => (
                        <p className=' bg-gray-800/30 text-sm border border-gray-700 text-gray-200 py-1.5 px-3 rounded-full' key={index}>{genre.name}</p>
                        ))}
                    </div>
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-3 mb-1 hidden md:block'>Tagline</div>
                    <div className=' bg-gray-800/30 text-sm border border-gray-700 text-gray-200 py-1.5 px-3 rounded-full w-max hidden md:block'>{Details.tagline}</div>
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-3 mb-1 hidden md:block'>Production Companies</div>
                    <div className=' items-center gap-3 pl-2 hidden md:flex'>
                    {Details.production_companies.map((company, index) => (
                        company.logo_path && (
                            <img key={index} className='w-16' src={`https://image.tmdb.org/t/p/original/${company.logo_path}`} alt="" />
                        )
                    ))}
                    </div>
                    <div className='text-sm text-gray-200 opacity-50 pl-2 mt-3 mb-1'>Overview / Plot</div>
                    <p className='text-sm md:w-[70%] pl-2 text-gray-200'>{Details.overview}</p>

                    <div className='flex items-center gap-2 mt-4'>
                    {trailer && 
                     <div>
                     <button
                       className='text-xl bg-gray-900/30 border border-gray-400/50 hover:bg-yellow-500 hover:border-yellow-200 hover:text-black rounded-full flex items-center justify-center py-2.5 px-4 gap-2'
                       onClick={handlePlayTrailer}
                     >
                       <PiVideoBold />
                       <span className='text-sm'>Play Trailer</span>
                     </button>
               
                     
                   </div>
                    }
                    <button 
                    onClick={handleMoviesList}
                    className={`${isInList ? ' bg-yellow-500 text-black font-semibold' : 'bg-gray-900/30'} text-xl bg-gray-900/30 border border-gray-400/50 hover:bg-yellow-500 hover:border-yellow-200 hover:text-black rounded-full flex items-center justify-center py-2.5 px-4 gap-2`}>
                    {isInList ? (
                        <>
                        <MdOutlinePlaylistAddCheck />
                        <span className=' text-sm'>Remove From List</span>
                        </>
                    ):(
                        <>
                    <MdOutlinePlaylistAdd />
                    <span className=' text-sm'>Add To List</span>
                        </>
                    )}
                    
                    </button>
                    </div>
                    <button onClick={showLinks} className='text-xl md:hidden mr-2 mb-28 font-semibold bg-gray-900/30 border border-gray-400/50 bg-yellow-500 border-yellow-200 text-black rounded-full flex items-center justify-center py-2.5 px-4 gap-2 mt-4'>Play Streaming</button>
                    </div>
                    
                    <div
                id='Links'
                className={`md:col-span-3 h-screen pt-16 md:pr-12 absolute md:relative md:top-0 top-0 w-full ${
                    isLinksVisible ? '' : 'hidden md:block'
                }`}
            >
                    <div className='bg-gray-950 h-full rounded-t-xl border-b-0 border border-gray-700 p-6 relative '>
                    <div id='CloseLinks' className='flex justify-end text-3xl md:hidden'>
                        <IoCloseCircle onClick={hideLinks} />
                    </div>
                <h1 className='mb-5'>Play {Details.title} Movie</h1>
              
                
                
                
             <Link to={`/test/${Details.imdb_id}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> AWSindStream <span className='text-xs font-normal'>(Video Ads)</span>
             </Link>
             <Link to={`/videosrc/${Details.imdb_id}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> VideoStream <span className='text-xs font-normal'>(5% POP Ads)</span>
             </Link>
             <Link to={`/vidsrcc/${Details.id}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> VidSrcCC <span className='text-xs font-normal'>(No Ads)</span>
             </Link>
             <Link to={`/tembedstream/${Details.id}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> TembedStream <span className='text-xs font-normal'>(30% POP Ads)</span>
             </Link>
             <Link to={`/superstream/${Details.imdb_id}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> SuperStream <span className='text-xs font-normal'>(10% POP Ads)</span>
             </Link>
             <Link to={`/towstream/${Details.imdb_id}`} className='flex items-center justify-start gap-2 w-full text-sm bg-gray-800/50 p-1.5 rounded-full font-bold mb-2'>
                <div className=' bg-yellow-500 w-10 h-10 flex items-center justify-center rounded-full'><FaPlay /></div> TwoStream <span className='text-xs font-normal'>(20% POP Ads)</span>
             </Link>
                
                    </div>
                </div>
                </div>
               
                </div>
            </div>
        }

{isTrailerPlaying && (
                       <div
                         id='TrailerVideo'
                         className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50'
                         onClick={handleCloseTrailer}
                       >
                         <div className='md:w-[60%] md:h-[65vh] w-[97%] h-[40vh] border border-yellow-500 rounded-2xl overflow-hidden'>
                           <iframe
                             src={`https://www.youtube.com/embed/${trailer.key}?autoplay=1`}
                             allow="autoplay"
                             title={trailer.name}
                             allowFullScreen
                             className='w-full h-full'
                           ></iframe>
                         </div>
                       </div>
                     )}

    </div>
);
};

export default Details;

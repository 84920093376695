import React, { useEffect, useState } from 'react'
import { Link, } from 'react-router-dom';
import { PiMonitorPlayFill } from "react-icons/pi";
import Nav from './components/Nav'
import { useParams } from 'react-router-dom';
import config from './config.json';
import axios from 'axios';

const Search = () => {
  const { query } = useParams();
  const [liveSearch, setLiveSearch] = useState([]);

  useEffect(() => {
    const search = async () => {
        try {
            const firstResponse = await axios.get(`${config.TMDBapiUrl}search/multi`, {
                headers: {
                    Authorization: `Bearer ${config.TMDBToken}`
                },
                params: {
                    query: query,
                    include_adult: false,
                    language: 'en-US',
                    page: 1,
                    api_key: config.TMDBapikey
                }
            });
            
            let totalPages = firstResponse.data.total_pages;
            
            // Limit totalPages to 10 if it exceeds 10
            if (totalPages > 10) {
                totalPages = 10;
            }

            let allResults = firstResponse.data.results;

            // Fetch all other pages up to the limited totalPages
            for (let page = 2; page <= totalPages; page++) {
                const response = await axios.get(`${config.TMDBapiUrl}search/multi`, {
                    headers: {
                        Authorization: `Bearer ${config.TMDBToken}`
                    },
                    params: {
                        query: query,
                        include_adult: false,
                        language: 'en-US',
                        page: page,
                        api_key: config.TMDBapikey
                    }
                });
                allResults = allResults.concat(response.data.results);
            }

            const filteredResults = allResults.filter(result => 
                result.media_type === 'tv' || result.media_type === 'movie'
            );
            
            setLiveSearch(filteredResults);
        } catch (error) {
            console.error('Error fetching videos', error);
        }
    };
    search();
}, [query]);



  return (
    <div>
      <Nav/>
      <div className='md:px-12 px-4 pt-16'>
        <div className="grid md:grid-cols-10 grid-cols-3 gap-3">
        {liveSearch.map((movie, index) => {
            const isTV = movie.media_type === 'tv';
            const movieTitle = isTV ? movie.name.replace(/\s+/g, '-') : movie.title.replace(/\s+/g, '-');
            return (
              <Link
                to={`${isTV ? '/tv' : ''}/${movie.id}/${movieTitle}`}
                className='w-full h-max relative'
                key={index}
              >
                <img
                  className='w-full  object-cover rounded-md z-10 top-0 right-0'
                  loading='lazy'
                  src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`}
                  alt={movieTitle}
                />
                <div className='w-full h-full rounded-md bg-gray-600 top-0 left-0'></div>
                <div className='absolute z-20 opacity-0 hover:opacity-100 transition-all duration-300 w-full h-full bg-black/70 top-0 right-0 backdrop-blur-sm p-2 text-center flex flex-col items-center justify-center'>
                  <p className='text-xs'>{movieTitle}</p>
                  <PiMonitorPlayFill className='text-3xl text-yellow-500' />
                </div>
              </Link>
            );
          })}

        </div>
      </div>
    </div>
  )
}

export default Search

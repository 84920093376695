import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

const PlayTrailer = () => {
    const { key } = useParams();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const onYouTubeIframeAPIReady = () => {
            setReady(true);
        };

        // Check if the YouTube iframe API is already loaded
        if (window.YT && window.YT.Player) {
            onYouTubeIframeAPIReady();
        } else {
            // Create a script tag to load the YouTube iframe API
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            // Set the callback to be triggered once the API is ready
            window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
        }

        return () => {
            // Clean up the callback to avoid memory leaks
            window.onYouTubeIframeAPIReady = null;
        };
    }, []);

    return (
        <div className='w-full fixed top-0 left-0 z-50'>
            <div className='w-full h-screen'>
                {ready && 
                <iframe src={`https://www.youtube.com/embed/${key}?autoplay=1`} allow="autoplay" allowFullScreen className='w-full h-screen'></iframe>
                }
            </div>
        </div>
    );
};

export default PlayTrailer;
